<script setup lang='ts'>
import { APP_ROUTER } from '@qctsw/common'
import { logicSeriesTabList } from '@/logic/home/seriesTabList'

const {
  state,
  activeTab,
  tabs,
  getTabList,
} = logicSeriesTabList()

await getTabList()
</script>

<template>
  <UtilLoadState :state="state" :reload-handler="getTabList">
    <NTabs v-model:value="activeTab" type="bar" animated trigger="click" class="bg-gray-1 px-lg py-0.5 rounded text-3">
      <NTab v-for="v, i in tabs" :key="v.name" :style="{ '--n-pane-padding': '3rem 0' }" :name="i">
        {{ v.name }}
      </NTab>
    </NTabs>
    <table v-for="tab, index in tabs" v-show="index === activeTab" :key="index" class="w-full">
      <caption ssr-only>
        {{ tab.name }}
      </caption>
      <tbody>
        <tr v-for="row in Math.ceil(tab.list.length / 10)" :key="row" class="grid grid-cols-10 gap-x-2">
          <td v-for="v, i in tab.list.slice(10 * (row - 1), 10 * row)" :key="i">
            <NuxtLink
              :to="APP_ROUTER.reputationDetail(v.carSeriesId)" :title="v.carSeriesName"
              class="text-4 truncate block w-full text-center py-1 mt-3 hover:text-orange-6 hover:bg-gray-1 hover:rounded hover:shadow"
            >
              {{ v.carSeriesName }}
            </NuxtLink>
          </td>
        </tr>
      </tbody>
    </table>
  </UtilLoadState>
</template>

<style lang='scss' scoped>

</style>
